import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ZOOM_IN } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import {
  setClickedFeature,
  setCurrentCollectionAndCategory,
} from 'state/Collections/actions'
import { selectClickedFeature } from 'state/Collections/selectors'

import { Avatar } from 'components/common'
import isMobile from 'utils/isMobile'

import { setPanelVisibility } from 'state/Ui/actions'
import { RowStyled } from './Row.styled'

const Row = ({ style, index, data, setView }) => {
  const { collection } = data[index]
  const { category } = data[index].properties
  const dispatch = useDispatch()
  const clickedFeature = useSelector(selectClickedFeature)
  const isSelected =
    clickedFeature?.properties?.name === data[index].properties.name &&
    clickedFeature?.properties?.category === data[index].properties.category

  const hiddePanel = () => dispatch(setPanelVisibility(false))

  const handleClick = useCallback(
    (item) => {
      dispatch(setCurrentCollectionAndCategory({ collection, category }))
      dispatch(setClickedFeature(item))
      setView(item, ZOOM_IN)
      isMobile && hiddePanel()
    },
    [setView],
  )

  return (
    <RowStyled
      isSelected={isSelected}
      style={style}
      onClick={() => handleClick(data[index])}
    >
      <Avatar {...{ collection, category }} />
      <div className="text--block">
        <span className="title">{data[index].properties.name.toLowerCase()}</span>
        <span className="subtitle">{data[index].properties.address}</span>
      </div>
    </RowStyled>
  )
}

Row.propTypes = {
  setView: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
}

export default Row
