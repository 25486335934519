import { FlyToInterpolator } from 'react-map-gl'
import * as d3 from 'd3-ease'
import theme from 'styles/theme'
import { getKey } from 'utils/key-generator'
import { COLLECTION_NAMES_LIST, DEFAULT_POSITION_INV } from 'constants/index'
import { useCenter } from 'hooks'
import { getCollectionFromID } from 'utils'

const _getMapColorsStops = (categoriesConfig) => {
  if (!categoriesConfig.length) {
    return [['default', theme.colors.darkBlue]]
  }

  return categoriesConfig.map(({ name, mapColor, accentColor } = {}) => {
    const color = mapColor
      ? theme.colors[mapColor?.color]
      : theme.colors[accentColor.color]

    return [name, color]
  })
}

const setDataLayer = (
  collection,
  options = { usePolygon: false, usePath: false, useLine: false },
  categoriesConfig,
) => {
  const { usePolygon, usePath, useLine } = options
  let type = 'circle'
  if (usePolygon) {
    type = 'fill'
  } else if (useLine) {
    type = 'line'
  }

  const drawPath = usePath ? { 'fill-color': theme.colors.transparent } : null
  const drawLine = useLine
    ? { 'line-color': theme.colors.darkBlue, 'line-width': 1.5 }
    : null

  return {
    id: `${collection.name}##${getKey()}`,
    type,
    paint: {
      [`${type}-color`]: {
        type: 'categorical',
        property: 'category',
        stops: _getMapColorsStops(categoriesConfig),
      },
      ...drawPath,
      ...drawLine,
      [`${type}-opacity`]: 0.8,
    },
  }
}

const getClickedFeature = (event) => {
  const { features } = event || {}

  const clickedFeature =
    features &&
    features.find((feature) => {
      const name = getCollectionFromID(feature)
      return COLLECTION_NAMES_LIST.includes(name)
    })

  return clickedFeature || false
}

const flyTo = (feature, zoom) => {
  const [long, lat] = useCenter(feature) || DEFAULT_POSITION_INV

  return {
    longitude: long,
    latitude: lat,
    zoom,
    transitionDuration: 800,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: d3.easeCubic,
  }
}

export { setDataLayer, getClickedFeature, flyTo }
