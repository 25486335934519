import React from 'react'
import CommonStyles from './common';
import ResetStyles from './reset';

export const GlobalStyles = () => (
  <>
    <ResetStyles />
    <CommonStyles />
  </>
);
