import styled from 'styled-components'

export const PanelHeaderStyled = styled.header`
  display: flex;
  height: ${({ theme }) => theme.spacing.spacingL};
  background-color: ${({ theme }) => theme.colors.brand2};
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
  align-items: center;
  border-radius: 4px;
`
