import styled from 'styled-components'
import { getRem } from 'styles/utils'

export const ListStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${getRem(17)} ${getRem(10)};
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
`
