import React from 'react'
import PropTypes from 'prop-types'

import { IntroStyled } from './Intro.styled'

const Intro = ({ content }) => <IntroStyled>{content}</IntroStyled>

Intro.propTypes = {
  content: PropTypes.string.isRequired,
}

export default Intro
