import styled from 'styled-components'
import { lighten } from 'polished'
import { getRem } from 'styles/utils'

export const AvatarStyled = styled.div`
  position: relative;
  margin-right: 20px;
  > svg {
    height: ${getRem(48)};
    width: ${getRem(48)};
  }
  .category-icon {
    position: absolute;
    bottom: 6px;
    right: -2px;
    div {
      height: ${getRem(14)};
      width: ${getRem(14)};
      margin: 0;
      box-shadow: ${({ theme, color }) =>
        `-1px 0px 7px 0px ${lighten(0.5, theme.colors[color])}`};
    }
  }
`
