import { combineReducers } from 'redux'

import collections from './Collections/reducer'
import ui from './Ui/reducer'
import info from './Info/reducer'

const AppReducer = combineReducers({
  collections,
  ui,
  info,
})

const rootReducer = (state, action) => {
  return AppReducer(state, action)
}

export default rootReducer
