import { all, fork, takeLatest } from 'redux-saga/effects'

import Collections from './Collections/sagas'
import Info from './Info/sagas'

const sagas = {
  ...Collections,
  ...Info,
}

const watchers = Object.entries(sagas).map(
  ([key, value]) =>
    function* watchSaga() {
      yield takeLatest(key, value)
    },
)

export default function* root() {
  yield all(watchers.map((watcher) => fork(watcher)))
}
