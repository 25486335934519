import styled from 'styled-components'

export const BasicInputStyled = styled.div`
  flex-grow: 1;
  .input-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    input {
      width: 100%;
      padding: 0 10px 0 16px;
      border: none;
      box-sizing: border-box;
      height: ${({ theme }) => theme.spacing.spacingM};
      box-shadow: ${({ theme }) => theme.colors.shadow};
      border-radius: 4px;
      &:focus {
        border-color: ${({ theme }) => theme.colors.brand1};
      }
      &::placeholder {
        color: ${({ theme }) => theme.colors.gray20};
      }
    }
    .input-icon {
      position: absolute;
      top: 0;
      height: ${({ theme }) => theme.spacing.spacingM};
      pointer-events: none;
      color: ${({ theme }) => theme.colors.gray20};
      transform: scale(1.3);
      &--suffix {
        right: ${({ theme }) => theme.spacing.spacingXS};
      }
    }
    .cross-icon__wrap {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
      right: ${({ theme }) => theme.spacing.spacingXS};
      display: flex;
      align-items: center;
      svg {
        g {
          stroke: ${({ theme }) => theme.colors.gray50};
        }
      }
    }
  }

  .error {
    padding-top: 7px;
    color: ${({ theme }) => theme.colors.error};
    font-size: 0.8rem;
  }

  .label {
    visibility: hidden;
    height: 0;
    width: 0;
    opacity: 0;
    display: block;
    &--visible {
      display: initial;
      visibility: visible;
      height: initial;
      width: initial;
      opacity: 1;
    }
  }
`
