import React, { memo } from 'react'
import { ThemeProvider } from 'styled-components'
import ErrorBoundary from 'components/pages/ErrorBoundary'
import { MapboxMap } from 'components/layouts'
import { MainContainer } from 'styles/guideUtils'
import { GlobalStyles } from 'styles'
import theme from 'styles/theme'

const App = () => (
  <ErrorBoundary>
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <MainContainer>
        <MapboxMap />
      </MainContainer>
    </ThemeProvider>
  </ErrorBoundary>
)

export default memo(App)
