import styled from 'styled-components'
import { lighten } from 'polished'
import { getRem } from 'styles/utils'

import { ListBefore } from 'assets/icons/index'

export const NavigationStyled = styled.nav`
  margin: ${getRem(24)} 0;

  li {
    font-size: ${getRem(13)};
    cursor: pointer;
    padding: 8px 14px 4px;
    border-radius: 4px;
    background-color: transparent;
    transition: background-color 0.3s ease-out;

    &:not(:last-child) {
      margin-bottom: ${getRem(12)};
    }

    &:hover {
      background-color: ${({ theme }) => lighten(0.23, theme.colors.selected)};
    }
  }
`

export const ListBeforeStyled = styled(ListBefore)`
  margin: 0 8px 0 2px;
`
