function* keyGenerator() {
  let id = 0
  while (true) {
    id += 1
    yield `idGeneratorId${id}`
  }
}

const KeyGeneratorInit = keyGenerator()

export const getKey = () => KeyGeneratorInit.next().value
