import React from 'react'
import theme from 'styles/theme'
import { TodosIcon, ComercialIcon, LugaresIcon, GenteIcon } from 'assets/icons'
import { CircleForm } from 'styles/guideUtils'
import {
  MemoriaIconStyled,
  PropuestaIconStyled,
} from 'components/layouts/Panel/components/CollectionSelector/CollectionSelector.styled'

export const getCategoryAccentColor = (category, categoriesConfig) => {
  const { accentColor } = categoriesConfig.find(({ name }) => name === category) || {}

  return accentColor?.color || theme.colors.darkBlue
}

export const getCategoryComponent = (category, categoriesConfig) => {
  const options = {
    'Pequeño comercio': () => (
      <CircleForm color={getCategoryAccentColor(category, categoriesConfig)} />
    ),
    'Juegos de azar y apuestas': () => (
      <CircleForm color={getCategoryAccentColor(category, categoriesConfig)} />
    ),
    Propuesta: () => <PropuestaIconStyled />,
    Memoria: () => <MemoriaIconStyled />,
    Tradicional: () => (
      <CircleForm color={getCategoryAccentColor(category, categoriesConfig)} />
    ),
    Aliado: () => (
      <CircleForm color={getCategoryAccentColor(category, categoriesConfig)} />
    ),
    Orange: () => (
      <CircleForm color={getCategoryAccentColor(category, categoriesConfig)} />
    ),
    'Lugar Potencial': () => (
      <CircleForm color="transparent" borderColor={theme.colors.darkBlue} outlined />
    ),
    'Patrimonio Industrial': () => (
      <CircleForm color={getCategoryAccentColor(category, categoriesConfig)} />
    ),
    default: () => (
      <CircleForm color={getCategoryAccentColor(category, categoriesConfig)} />
    ),
  }

  return (options[category] || options.default)()
}

export const getCollectionIcon = (collection) => {
  const options = {
    Todos: <TodosIcon />,
    comercial: <ComercialIcon />,
    lugares: <LugaresIcon />,
    gente: <GenteIcon />,
    default: null,
  }

  return options[collection] || options.default
}

export const getCollectionFromID = (feature) => feature.layer.id.split('##')[0]
