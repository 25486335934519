import styled from 'styled-components'
import { getRem } from 'styles/utils'
import theme from './theme'

export const TextH1 = styled.h1`
  font-family: 'Roboto';
  font-size: ${getRem(65)};
  line-height: ${getRem(70)};
  line-height: 1;
`

export const TextH2 = styled.h2`
  font-family: 'Roboto';
  font-size: ${getRem(50)};
  line-height: ${getRem(50)};
  line-height: 1.08;
`

export const TextH3 = styled.h3`
  font-family: 'Roboto';
  font-size: ${getRem(30)};
  line-height: ${getRem(32)};
  line-height: 1.07;
`

export const TextH4 = styled.h4`
  font-family: 'Roboto';
  font-size: ${getRem(16)};
  line-height: ${getRem(22)};
  line-height: 1.22;
`

export const TextSubtitle = styled.p`
  font-family: 'Roboto';
  font-size: ${getRem(12)};
  line-height: ${getRem(22)};
  color: ${({ color }) => color && color};
`

export const Paragraph = styled.p`
  font-family: 'Roboto';
  font-size: ${getRem(13)};
  line-height: ${getRem(22)};
  line-height: 1.54;
  letter-spacing: -0.26px;
  color: ${theme.textColor};
`

export const TextRight = styled.p`
  text-align: right;
`
