/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'

import { SearchIcon, CrossIcon } from 'assets/icons'

import { useDispatch } from 'react-redux'
import { setCollectionsSearchTerm } from 'state/Collections/actions'

import { BasicInputStyled } from './BasicInput.styled'

const BasicInput = ({
  error,
  id,
  label,
  name,
  value,
  onChange,
  placeholder,
  showLabel,
}) => {
  const dispatch = useDispatch()
  const handleOnChange = ({ target }) => onChange(target.value)
  const hasTerm = value === ''

  const deleteCurrentSearch = () => dispatch(setCollectionsSearchTerm(''))

  return (
    <BasicInputStyled>
      <label
        className={`label ${showLabel ? 'label--visible' : ''}`}
        htmlFor={id}
        aria-hidden={!showLabel}
      >
        <span>{label}</span>
      </label>
      <div className="input-wrapper">
        <input
          name={name}
          value={value}
          onChange={handleOnChange}
          placeholder={placeholder}
        />
        {hasTerm ? (
          <SearchIcon className="input-icon input-icon--suffix" />
        ) : (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div onClick={deleteCurrentSearch} className="cross-icon__wrap">
            <CrossIcon />
          </div>
        )}
      </div>
      {error && <div className="error">{error}</div>}
    </BasicInputStyled>
  )
}

BasicInput.defaultProps = {
  value: '',
  name: '',
  error: null,
  placeholder: '',
  showLabel: false,
}

BasicInput.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  showLabel: PropTypes.bool,
}

export default BasicInput
