import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { selectFlatenFeatures } from 'state/Collections/selectors'

import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { BreadCrumb } from 'components/common'
import Row from './components/Row/Row.component'

import { ListStyled } from './List.styled'

const List = ({ setView }) => {
  const ref = useRef()
  const collections = useSelector(selectFlatenFeatures)

  if (!collections) return null

  const { length } = collections

  return (
    <ListStyled>
      <BreadCrumb length={length} />
      <AutoSizer>
        {({ width, height }) => (
          <FixedSizeList
            itemCount={length}
            ref={ref}
            height={height / 1.86}
            itemSize={88}
            itemData={collections}
            width={width}
          >
            {(props) => <Row setView={setView} {...props} />}
          </FixedSizeList>
        )}
      </AutoSizer>
    </ListStyled>
  )
}

List.propTypes = {
  setView: PropTypes.func.isRequired,
}

export default List
