import React from 'react'
import { Source, Layer } from 'react-map-gl'

import { useSelector, useDispatch } from 'react-redux'
import { selectCollections, selectCategoriesConfig } from 'state/Collections/selectors'
import { setTotalFeatures } from 'state/Collections/actions'

import { getKey } from 'utils/key-generator'
import { setDataLayer } from '../../MapboxMap.helpers'

const GeoJSONLayers = () => {
  const dispatch = useDispatch()
  const collections = useSelector(selectCollections)
  const categoriesConfig = useSelector(selectCategoriesConfig)

  if (!collections) return null

  const totalFeatures = Object.keys(collections).reduce(
    (acc, curr) => acc + collections[curr].features.length,
    0,
  )
  dispatch(setTotalFeatures(totalFeatures))

  return Object.keys(collections).map((collection) => {
    return (
      <Source type="geojson" data={collections[collection]} key={getKey()}>
        {/* draw every and only point type features */}
        <Layer
          {...setDataLayer(collections[collection], {}, categoriesConfig)}
          filter={['in', '$type', 'Point']}
        />

        {/* draw only polygons with fill aka Lugar Potencial */}
        <Layer
          {...setDataLayer(
            collections[collection],
            {
              usePolygon: true,
            },
            categoriesConfig,
          )}
          filter={
            (['!in', '$type', 'Point'], ['in', 'category', 'Patrimonio Industrial'])
          }
        />

        {/* draw polygons with outline ie. Patrimonio Industrial,
        but the outline need a 'line hack' layer to handle the width */}
        <Layer
          {...setDataLayer(
            collections[collection],
            {
              usePolygon: true,
              usePath: true,
            },
            categoriesConfig,
          )}
          filter={(['!in', '$type', 'Point'], ['in', 'category', 'Lugar Potencial'])}
        />
        <Layer
          {...setDataLayer(
            collections[collection],
            {
              useLine: true,
            },
            categoriesConfig,
          )}
          filter={(['!in', '$type', 'Point'], ['in', 'category', 'Lugar Potencial'])}
          type="line"
        />
      </Source>
    )
  })
}

export default React.memo(GeoJSONLayers)
