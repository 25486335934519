import styled from 'styled-components'
import { getRem } from 'styles/utils'

export const CircleForm = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ color, theme }) => theme.colors[color]};
  margin-right: ${getRem(6)};
  border-radius: 50%;
  border: ${({ outlined, borderColor }) => outlined && `1px solid ${borderColor}`};
`

export const MainContainer = styled.main`
  display: flex;
`
