export default {
  collectionsNames: [],
  collectionsList: null,
  currentCollection: 'Todos',
  searchTerm: '',
  error: false,
  loading: false,
  clickedFeature: null,
  currentCategory: null,
  totalFeatures: 0,
  categories: {},
  categoriesConfig: [],
}
