import * as ActionTypes from './types'

export const setPanelVisibility = (payload) => {
  return {
    type: ActionTypes.UI_SET_PANEL_VISIBILITY,
    payload,
  }
}

export const setListVisibility = (payload) => {
  return {
    type: ActionTypes.UI_SET_LIST_VISIBILITY,
    payload,
  }
}

export const setMobileCardVisibility = (payload) => {
  return {
    type: ActionTypes.UI_SET_MOBILE_CARD_VISIBILITY,
    payload,
  }
}
