import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { BasicInput } from 'components/common'
import { useDispatch } from 'react-redux'
import { setCollectionsSearchTerm } from 'state/Collections/actions'

import { SearchBarStyled } from './SearchBar.styled'

const SearchBar = ({ searchTerm, onSetSearchTerm }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(setCollectionsSearchTerm(''))
    }
  }, [])

  return (
    <SearchBarStyled>
      <BasicInput
        value={searchTerm}
        onChange={onSetSearchTerm}
        id="main-search"
        label="Buscar"
        placeholder="Buscar"
      />
    </SearchBarStyled>
  )
}

SearchBar.propTypes = {
  onSetSearchTerm: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
}

export default SearchBar
