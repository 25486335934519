/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'react-map-gl'

import { useDispatch, useSelector } from 'react-redux'
import { useCenter } from 'hooks'
import { selectClickedFeature, selectCategoriesConfig } from 'state/Collections/selectors'
import { CrossIcon, GenteIcon } from 'assets/icons'
import isMobile from 'utils/isMobile'
import { setMobileCardVisibility } from 'state/Ui/actions'

import { getCategoryAccentColor } from 'utils/index'

import { TextSubtitle, TextH4 } from 'styles/text'
import { TooltipStyled } from './Tooltip.styled'

const Tooltip = ({ handleTooltipClose }) => {
  const handleClose = () => handleTooltipClose()
  const dispatch = useDispatch()

  const openMobileCard = () => isMobile && dispatch(setMobileCardVisibility(true))

  const feature = useSelector(selectClickedFeature)
  const categoriesConfig = useSelector(selectCategoriesConfig)

  if (!feature) return null

  const {
    properties: { name, activity, category },
  } = feature || {}

  const accentColor = getCategoryAccentColor(category, categoriesConfig)

  const [long, lat] = useCenter(feature)

  return (
    feature && (
      <TooltipStyled color={accentColor} onClick={openMobileCard}>
        <Popup
          latitude={lat}
          longitude={long}
          anchor="bottom"
          closeButton={false}
          offsetTop={-6}
          maxWidth={180}
        >
          <div className="tooltip__wrap">
            <button
              className="mapboxgl-popup-close-button"
              type="button"
              onClick={handleClose}
            >
              <CrossIcon />
            </button>
            <div className="tooltip__icon-wrap">
              <GenteIcon />
            </div>
            <div className="tooltip__text-wrap">
              <TextH4 className="tooltip__text-name">{name.toLowerCase()}</TextH4>
              <TextSubtitle className="tooltip__text-activity">{activity}</TextSubtitle>
            </div>
          </div>
        </Popup>
      </TooltipStyled>
    )
  )
}

Tooltip.propTypes = {
  handleTooltipClose: PropTypes.func.isRequired,
}

export default React.memo(Tooltip)
