import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import isMobile from 'utils/isMobile'

import {
  selectPanelIsVisible,
  selectListIsVisible,
  selectMobileCardIsVisible,
} from 'state/Ui/selectors'
import * as collectionsSelectors from 'state/Collections/selectors'
import { setProjectInfo, setCardContent } from 'state/Info/actions'
import { setCollectionsSearchTerm, setClickedFeature } from 'state/Collections/actions'
import { setListVisibility } from 'state/Ui/actions'
import { useDispatch, useSelector } from 'react-redux'

import { LogoIcon } from 'assets/icons'
import { SearchBar } from 'components/layouts'
import {
  CollectionSelector,
  List,
  PanelHandler,
  Card,
  MobileCard,
  ProjectInfo,
} from './components'

import { PanelContainerStyled } from './Panel.styled'
import { PanelHeaderStyled } from './components/Header/Header.styled'

export const Panel = ({ setView }) => {
  const panelIsVisible = useSelector(selectPanelIsVisible)
  const listIsVisible = useSelector(selectListIsVisible)

  const mobileCardIsVisible = useSelector(selectMobileCardIsVisible)
  const searchTerm = useSelector(collectionsSelectors.selectSearchTerm)
  const clickedFeature = useSelector(collectionsSelectors.selectClickedFeature)
  const dispatch = useDispatch()
  const setSearch = (value) => dispatch(setCollectionsSearchTerm(value))

  const handleListVisibility = () => {
    dispatch(setListVisibility(false))
    dispatch(setClickedFeature(null))
    dispatch(setProjectInfo(null))
    dispatch(setCardContent(null))
  }

  useEffect(() => {
    return () => {
      dispatch(setCollectionsSearchTerm(''))
    }
  }, [])

  return (
    <PanelContainerStyled {...{ panelIsVisible, clickedFeature, listIsVisible }}>
      <section className="panel__inner">
        <PanelHeaderStyled>
          <LogoIcon onClick={handleListVisibility} className="pointer" />
          <PanelHandler />
        </PanelHeaderStyled>
        <CollectionSelector {...{ setView }} />
        {listIsVisible && (
          <>
            <SearchBar searchTerm={searchTerm} onSetSearchTerm={setSearch} />
            <List {...{ setView }} />
          </>
        )}
        {!listIsVisible && <ProjectInfo />}
      </section>
      {clickedFeature && !isMobile && <Card {...{ setView, clickedFeature }} />}
      {clickedFeature && isMobile && mobileCardIsVisible && (
        <MobileCard {...{ setView, clickedFeature }} />
      )}
    </PanelContainerStyled>
  )
}

Panel.propTypes = {
  setView: PropTypes.func.isRequired,
}

export default React.memo(Panel)
