import styled from 'styled-components'
import { getRem } from 'styles/utils'
import { TextH4 } from 'styles/text'

export const CardStyled = styled.section`
  background: ${({ theme }) => theme.colors.white};
  background-image: ${({ theme, color }) =>
    `linear-gradient(to bottom, ${theme.colors[color]}, #ffffff 22%)`};
  border-left: 1px solid ${({ theme }) => `${theme.colors.gray20}`};
  position: absolute;
  top: ${({ theme }) => theme.components.CardYPos};
  left: ${({ clickedFeature, panelIsVisible, theme }) =>
    clickedFeature && panelIsVisible ? theme.components.panelWidth : 0};
  overflow: scroll;
  transition: left 0.3s ease-out;
  transition-delay: 0.4s;
  word-break: break-word;
  width: ${({ theme }) => theme.components.panelWidth};
  height: auto;
  z-index: -2;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: ${({ theme }) => theme.components.panelShadow};
  p {
    & > svg {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.white};
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
  .text-subtitle__content {
    color: ${({ color }) => color};
  }
`

export const CardHeaderStyled = styled.header`
  display: flex;
  align-items: center;
  padding-top: ${getRem(18)};
  p {
    text-transform: capitalize;
  }
  .header__names-wrap {
    display: flex;
  }
`

export const CardTextH4 = styled(TextH4)`
  text-transform: capitalize;
  font-weight: 400;
`

export const LineStyled = styled.div`
  width: 100%;
  height: 1px;
  margin: ${getRem(24)} 0;
  border: solid 1px ${({ theme }) => theme.colors.gray10};
`

export const AdressStyled = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 6px;
    flex-shrink: 0;
    align-self: flex-start;
  }
  p {
    font-size: ${getRem(12)};
    padding-left: ${getRem(18)};
  }
`

export const CardBoxedContentStyled = styled.div`
  padding: 0 ${getRem(24)};
`

export const CardContainerStyled = styled.div`
  height: 100%;
  width: 100%;
  box-shadow: inset 4px 0px 9px -8px rgba(0, 0, 0, 0.75);
  padding: ${getRem(16)} ${getRem(16)} ${getRem(32)};
`
