import styled from 'styled-components'
import Markdown from 'markdown-to-jsx'
import { getRem } from 'styles/utils'

export const IntroStyled = styled(Markdown)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: ${getRem(18)};
    letter-spacing: 0.5px;
    margin-bottom: ${getRem(16)};
    padding: 0 14px;
  }
  p {
    margin-bottom: ${getRem(16)};
    font-size: ${getRem(14)};
    line-height: 1.8;
    color: ${({ theme }) => theme.colors.textColor};
    padding: 0 14px;
  }
`
