/* eslint-disable prefer-template */
export const getDesktopVW = (target) => {
  const designWidth = 1440
  const vwContext = designWidth * 0.01
  const contextPercentage = target / vwContext
  return contextPercentage.toString() + 'vw'
}

export const getDesktopVH = (target) => {
  const designWidth = 806
  const vwContext = designWidth * 0.01
  const contextPercentage = target / vwContext
  return contextPercentage.toString() + 'vh'
}

export const getMobileVW = (target) => {
  const designWidth = 375
  const vwContext = designWidth * 0.01
  const contextPercentage = target / vwContext
  return contextPercentage.toString() + 'vw'
}

export const getMobileVH = (target) => {
  const designWidth = 670
  const vwContext = designWidth * 0.01
  const contextPercentage = target / vwContext
  return contextPercentage.toString() + 'vh'
}

export const getRem = (target) => {
  const fontSizeBase = 16
  const remSize = target / fontSizeBase
  return `${remSize}rem`
}
