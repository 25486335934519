import axios from 'axios'
import axiosWrap from './axios'
import logError from './logger'

export const getAll = () => {
  return axiosWrap({ url: '/feature-collections', method: 'GET' })
    .then(({ data }) => data)
    .catch('ERROR GETALL AXIOS CALL', logError)
}

export const getCollection = (collectionName) => {
  return axiosWrap({ url: `/uploads/geojson/${collectionName}.geojson`, method: 'GET' })
    .then(({ data }) => data)
    .catch('ERROR GETCOLLECTION AXIOS CALL', logError)
}

export const getCategoriesConfig = () => {
  return axiosWrap({ url: '/categories', method: 'GET' })
    .then(({ data }) => data)
    .catch('ERROR GETCATEGORIES AXIOS CALL', logError)
}

export const getFakeCollection = (url) => {
  return axios({ url, method: 'GET' })
    .then(({ data }) => data)
    .catch('ERROR GETCOLLECTION AXIOS CALL', logError)
}
