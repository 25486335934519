import styled from 'styled-components'

export const FooterStyled = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: -6px;
  width: calc(100% + 6px);
  border-top: 1px solid ${({ theme }) => theme.colors.gray20};
  padding: 24px 20px 8px;
  font-size: 10px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.textColor};
  .footer {
    &__nav {
      margin-top: 12px;
      ul {
        display: flex;
      }
      li {
        &:not(:last-child) {
          &:after {
            content: '|';
            margin-right: 4px;
            margin-left: 4px;
          }
        }

        & > span {
          cursor: pointer;
        }
      }
    }
    &__social {
      a:first-child {
        margin-right: 8px;
      }
    }
  }
`
