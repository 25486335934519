import { getRem } from 'styles/utils'

const theme = {
  colors: {
    brand1: '#FFC845',
    brand2: '#000098',
    brand3: '#89813D',
    brand4: '#625d20',
    textColor: '#3e3e3e',
    selected: '#ffa46f',
    black: '#000000',
    gray10: '#f5f5f5',
    gray20: '#cbcbcb',
    gray03: 'rgb(0,0,0,0.03)',
    gray50: '#808080',
    error: '#ff0000',
    white: '#ffffff',
    brandTwentyOpacity: '#f5eac1',
    veryLightPink: '#ebebeb',
    blue: 'rgb(98,210,236)',
    darkBlue: '#070594',
    naviBlue: '#00b8ff',
    orange: '#ff6a13',
    paleOrange: '#fcc258',
    fluorGreen: '#9cff00',
    darkGreen: '#77bc0c',
    brown: '#cc9437',
    shadow: '0 1px 2px 0 rgba(0, 0, 0, 0.15)',
    transparent: 'rgba(0, 0, 0, 0)',
  },
  breakpoints: {
    xs: '480px',
    sm: '768px',
    md: '992px',
    ml: '1080px',
    lg: '1440px',
    xl: '1920px',
  },
  spacing: {
    spacingXXS: getRem(4),
    spacingXS: getRem(10),
    spacingS: getRem(14),
    spacingM: getRem(32),
    spacingL: getRem(48),
  },
  components: {
    headerH: getRem(56),
    panelTopPos: getRem(12),
    panelLeftPos: getRem(12),
    panelWidth: getRem(302),
    panelHeight: `calc(100vh - ${getRem(12 * 2)})`,
    tooltipWidth: getRem(255),
    tooltipMinHeight: getRem(75),
    panelHandlerWidth: getRem(72),
    panelHandlerXPos: getRem(12),
    panelShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.32)',
    CardYPos: getRem(60),
  },
}

export default theme
