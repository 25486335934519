import * as types from './types'
import initialState from './initialState'

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UI_SET_PANEL_VISIBILITY:
      return {
        ...state,
        panelIsVisible: action.payload,
      }
    case types.UI_SET_LIST_VISIBILITY:
      return {
        ...state,
        listIsVisible: action.payload,
      }
    case types.UI_SET_MOBILE_CARD_VISIBILITY:
      return {
        ...state,
        mobileCardIsVisilbe: action.payload,
      }
    default:
      return state
  }
}
