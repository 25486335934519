import styled from 'styled-components'
import { getRem } from 'styles/utils'
import { TextH4 } from 'styles/text'

export const CardStyled = styled.section`
  background: #ffffff;
  background-image: linear-gradient(to bottom, #9cff00, #ffffff 22%);
  border-left: 1px solid #cbcbcb;
  position: fixed;
  top: 4.75rem;
  left: 12px;
  overflow-y: scroll;
  -webkit-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
  word-break: break-word;
  width: calc(100vw - 24px);
  height: auto;
  max-height: 100vh;
  z-index: -2;
  border-radius: 4px;

  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.32);
  p {
    & > svg {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.white};
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
`

export const CardHeaderStyled = styled.header`
  display: flex;
  align-items: center;
  padding-top: ${getRem(18)};
  p {
    text-transform: capitalize;
  }
  .header__names-wrap {
    display: flex;
  }
`

export const CardTextH4 = styled(TextH4)`
  text-transform: capitalize;
  font-weight: 400;
`

export const LineStyled = styled.div`
  width: 100%;
  height: 1px;
  margin: ${getRem(24)} 0;
  border: solid 1px ${({ theme }) => theme.colors.gray10};
`

export const AdressStyled = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 6px;
    flex-shrink: 0;
    align-self: flex-start;
  }
  p {
    font-size: ${getRem(12)};
    padding-left: ${getRem(18)};
  }
`

export const CardBoxedContentStyled = styled.div`
  padding: 0 ${getRem(24)};
`

export const CardContainerStyled = styled.div`
  height: 100%;
  width: 100%;
  padding: ${getRem(16)} ${getRem(16)} ${getRem(32)};
`
