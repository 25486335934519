import React, { useState, useEffect, useCallback } from 'react'
import MapGL from 'react-map-gl'

import { useDispatch } from 'react-redux'
import { getCollectionFromID } from 'utils'
import {
  getAllCollections,
  setClickedFeature,
  setCurrentCollectionAndCategory,
} from 'state/Collections/actions'

import { setListVisibility } from 'state/Ui/actions'

import { DEFAULT_POSITION, ZOOM_OUT, ZOOM_IN } from 'constants/index'

import { Panel } from 'components/layouts'
import { GeoJSONLayers, Tooltip } from './components'
import { getClickedFeature, flyTo } from './MapboxMap.helpers'

const MapboxMap = () => {
  const [viewport, setViewport] = useState({
    latitude: DEFAULT_POSITION[0],
    longitude: DEFAULT_POSITION[1],
    zoom: ZOOM_OUT,
    bearing: 0,
    pitch: 0,
  })

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllCollections())
  }, [])

  const setView = useCallback((feature, zoom) => {
    const newViewport = {
      ...viewport,
      ...flyTo(feature, zoom),
    }

    setViewport(newViewport)
  }, [])

  const handleClick = (event) => {
    const feature = getClickedFeature(event)
    if (!feature) return
    const collection = getCollectionFromID(feature)
    const { category } = feature.properties
    dispatch(setCurrentCollectionAndCategory({ collection, category }))
    dispatch(setClickedFeature(feature))
    dispatch(setListVisibility(true))
    setView(feature, ZOOM_IN)
  }

  const handleTooltipClose = () => {
    dispatch(setClickedFeature(null))
    setView(false, ZOOM_OUT)
  }

  return (
    <>
      <MapGL
        {...viewport}
        width="100vw"
        height="100vh"
        mapStyle="mapbox://styles/mapbox/light-v10"
        onViewportChange={setViewport}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onClick={handleClick}
        attributionControl={false}
      >
        <GeoJSONLayers />
        <Tooltip handleTooltipClose={handleTooltipClose} setView={setView} />
      </MapGL>
      <Panel setView={setView} />
    </>
  )
}

export default MapboxMap
