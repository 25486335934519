import React from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { setCardContent } from 'state/Info/actions'

import {
  CardStyled,
  CrossIconStyled,
  MarkdownStyled,
  LogoOrangeStyled,
} from './Card.styled'

const Card = ({ cardContent }) => {
  const dispatch = useDispatch()
  const handleClose = () => dispatch(setCardContent(null))
  const consistentImageSrc = (str) =>
    str.replace(/\/uploads/, `${process.env.REACT_APP_API_BASEURL}/uploads`)

  return (
    <CardStyled>
      <LogoOrangeStyled />
      <CrossIconStyled onClick={handleClose} />
      <MarkdownStyled>{consistentImageSrc(cardContent)}</MarkdownStyled>
    </CardStyled>
  )
}

Card.propTypes = {
  cardContent: PropTypes.string.isRequired,
}

export default Card
