import { getInfo } from 'api/info-api'
import { getLegal } from 'api/legal-api'
import { put, call } from 'redux-saga/effects'
import * as types from './types'
import * as actions from './actions'

function* getInfoSaga() {
  try {
    yield put(actions.setInfoLoading(true))
    const info = yield call(getInfo)
    const legal = yield call(getLegal)
    yield put(actions.setProjectInfo(info))
    yield put(actions.setLegalInfo(legal))
  } catch (error) {
    yield put(actions.setInfoError(true))
  } finally {
    yield put(actions.setInfoLoading(false))
  }
}

export default {
  [types.INFO_GET_PROJECT_INFO]: getInfoSaga,
}
