import * as ActionTypes from './types'

export const getProjectInfo = () => {
  return {
    type: ActionTypes.INFO_GET_PROJECT_INFO,
  }
}

export const setProjectInfo = (payload) => ({
  type: ActionTypes.INFO_SET_PROJECT_INFO,
  payload,
})

export const setLegalInfo = (payload) => ({
  type: ActionTypes.INFO_SET_LEGAL_INFO,
  payload,
})

export const setInfoLoading = (payload) => ({
  type: ActionTypes.INFO_SET_LOADING,
  payload,
})

export const setInfoError = (payload) => ({
  type: ActionTypes.INFO_SET_ERROR,
  payload,
})

export const setCardContent = (payload) => ({
  type: ActionTypes.INFO_SET_CARD_CONTENT,
  payload,
})
