import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPanelVisibility } from 'state/Ui/actions'
import { selectPanelIsVisible } from 'state/Ui/selectors'
import { SideArrow, SimplifiedLogo } from 'assets/icons'
import { PanelHandlerStyled } from './PanelHandler.styled'

const PanelHandler = () => {
  const panelIsVisible = useSelector(selectPanelIsVisible)
  const dispatch = useDispatch()
  const handlePanel = () => dispatch(setPanelVisibility(!panelIsVisible))

  useEffect(() => {
    return () => {
      dispatch(setPanelVisibility(true))
    }
  }, [])

  return (
    <PanelHandlerStyled
      onClick={handlePanel}
      panelIsVisible={panelIsVisible}
      className={`${!panelIsVisible ? 'is-hidden' : ''}`}
    >
      <div className="inner">
        {!panelIsVisible && (
          <figure>
            <SimplifiedLogo />
          </figure>
        )}
        <span>
          <SideArrow />
        </span>
      </div>
    </PanelHandlerStyled>
  )
}

export default PanelHandler
