import styled from 'styled-components'
import { getRem } from 'styles/utils'

export const SearchBarStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray20};
  background-color: ${({ theme }) => theme.colors.gray03};
  height: ${getRem(52)};
  padding: ${({ theme: { spacing } }) => {
    const upDown = spacing.spacingXS
    const rightLeft = spacing.spacingS
    return `${upDown} ${rightLeft}`
  }};
`
