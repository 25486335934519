import styled from 'styled-components'
import { lighten } from 'polished'
import { getRem } from 'styles/utils'
import { MemoriaIcon, PropuestaIcon } from 'assets/icons'

export const CollectionSelectorStyled = styled.div`
  max-height: 36vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  padding-top: ${({ theme }) => theme.spacing.spacingXS};
  padding-bottom: ${({ theme }) => theme.spacing.spacingXS};
`

const MenuItemStyled = styled.div`
  cursor: pointer;
  padding: 6px 14px 4px;
  border-radius: 4px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.selected : 'transparent'};
  width: 100%;
  display: flex;
  align-items: center;
  font-size: ${getRem(13)};
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: ${({ selected, theme }) =>
      !selected && lighten(0.23, theme.colors.selected)};
  }
`

export const CollectionMenuButton = styled(MenuItemStyled)`
  padding-left: ${getRem(14)};
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.spacingXXS};
  text-transform: capitalize;
  font-weight: 500;
  svg {
    margin-right: ${getRem(9)};
    width: 16px;
    height: 16px;
  }
`

export const CategoryMenuButton = styled(MenuItemStyled)`
  display: flex;
  margin-bottom: ${({ isLastChild }) => (isLastChild ? getRem(10) : getRem(5))};
  padding-left: ${getRem(40)};
`

export const MemoriaIconStyled = styled(MemoriaIcon)`
  margin-right: ${getRem(6)};
`

export const PropuestaIconStyled = styled(PropuestaIcon)`
  margin-right: ${getRem(6)};
`
