import styled from 'styled-components'
import { getRem } from 'styles/utils'

export const PanelHandlerStyled = styled.div`
  position: relative;
  right: 0;
  background: transparent;
  transition: right 0.3s ease-out;
  cursor: pointer;
  span {
    svg {
      transition: transform 0.4s ease-out;
      transform: ${({ panelIsVisible }) =>
        panelIsVisible ? 'rotate(0deg)' : 'rotate(180deg)'};
    }
  }
  &.is-hidden {
    width: ${({ theme }) => theme.components.panelHandlerWidth};
    height: ${({ theme }) => `calc(${theme.spacing.spacingL} + ${getRem(12)})`};
    position: absolute;
    right: ${({ theme }) =>
      `calc(-${theme.components.panelHandlerWidth} - ${theme.components.panelHandlerXPos})`};
    background: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.components.panelShadow};
    border-radius: ${getRem(4)};
    padding: 6px;
    .inner {
      height: 100%;
      background: ${({ theme }) => theme.colors.brand2};
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-radius: ${getRem(4)};
    }
  }
`
