import styled from 'styled-components'
import { getRem } from 'styles/utils'

export const TooltipStyled = styled.div`
  .mapboxgl-popup {
    min-width: ${getRem(180)};
    width: ${getRem(256)};
    max-width: 100%;
  }
  > div > div {
    border-bottom: 2px solid ${({ theme, color }) => theme.colors[color]};
    border-radius: 8px;
    background-image: ${({ theme, color }) =>
      `linear-gradient(to bottom, ${theme.colors[color]}, #ffffff 60%)`};
  }

  button {
    transform: scale(1.4);
    top: 2px;
    right: 8px;
    font-family: 'Roboto';
    font-weight: 100;
    color: white;

    &:hover {
      background-color: transparent;
    }
  }

  .tooltip {
    &__wrap {
      padding: 6px 20px 6px 6px;
      height: 100%;
      display: flex;
    }
    &__icon-wrap {
      display: flex;
      align-items: center;
      height: 50px;
      width: 50px;
      svg {
        height: 100%;
        width: 100%;
        g circle {
          fill: ${({ theme, color }) => theme.colors[color]};
        }
        g g {
          fill: white;
          circle {
            fill: white;
          }
        }
      }
    }
    &__text-wrap {
      margin-left: 12px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      h4 {
        font-weight: 500;
        text-transform: capitalize;
      }
      p {
        color: ${({ theme }) => theme.colors.gray50};
        font-size: ${getRem(10)};
        line-height: 1.2;
        margin-top: ${getRem(8)};
      }
    }
  }

  .mapboxgl-popup-tip {
    filter: drop-shadow(0 3px 0 ${({ theme, color }) => theme.colors[color]});
    position: relative;
    bottom: 4px;
  }
  .mapboxgl-popup-close-button {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    top: 9px;
    right: 9px;
    font-family: 'Roboto';
    font-weight: 100;
    color: white;
  }
`
