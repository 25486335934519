/* eslint-disable id-length */
import L from 'leaflet'

const useCenter = (feature) => {
  if (!feature) return null

  const { coordinates, type } = feature.geometry

  let lat = coordinates[1]
  let long = coordinates[0]

  if (type.toLowerCase().includes('polygon')) {
    const center = L.polygon(coordinates).getBounds().getCenter()
    lat = center.lng
    long = center.lat
  }

  return [long, lat]
}

export default useCenter
