import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    const { error, errorInfo } = this.state
    if (error) {
      return (
        <div className="error-boundary__container">
          <h1>Something went wrong.</h1>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo && errorInfo.componentStack}
          </details>
        </div>
      )
    }

    const { children } = this.props

    return children
  }
}

ErrorBoundary.defaultProps = {
  children: <></>,
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
}

export default ErrorBoundary
