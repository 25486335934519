import * as types from './types'
import initialState from './initialState'

export default (state = initialState, action) => {
  switch (action.type) {
    case types.INFO_SET_PROJECT_INFO:
      return {
        ...state,
        projectInfo: action.payload,
      }
    case types.INFO_SET_LEGAL_INFO:
      return {
        ...state,
        legalInfo: action.payload,
      }
    case types.INFO_SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case types.INFO_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case types.INFO_SET_CARD_CONTENT:
      return {
        ...state,
        cardContent: action.payload,
      }
    default:
      return state
  }
}
