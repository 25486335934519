import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ZOOM_OUT } from 'constants/index'

import { useDispatch, useSelector } from 'react-redux'
import { setClickedFeature } from 'state/Collections/actions'
import { selectPanelIsVisible } from 'state/Ui/selectors'
import {
  selectCurrentCollection,
  selectCategoriesConfig,
} from 'state/Collections/selectors'
import { Avatar } from 'components/common'
import { CrossIcon, LocatorIcon } from 'assets/icons'
import { getCategoryAccentColor } from 'utils/index'

import { Paragraph, TextSubtitle, TextRight } from 'styles/text'
import {
  CardHeaderStyled,
  CardStyled,
  CardTextH4,
  LineStyled,
  AdressStyled,
  CardContainerStyled,
  CardBoxedContentStyled,
} from './Card.styled'

const Card = ({ setView, clickedFeature }) => {
  const panelIsVisible = useSelector(selectPanelIsVisible)
  const dispatch = useDispatch()
  const collection = useSelector(selectCurrentCollection)
  const categoriesConfig = useSelector(selectCategoriesConfig)

  const onCloseCard = useCallback(() => {
    dispatch(setClickedFeature(null))
    setView(false, ZOOM_OUT)
  }, [setView])

  const {
    properties: { name, category, description, address, neighborhood, activity },
  } = clickedFeature

  const color = getCategoryAccentColor(category, categoriesConfig)

  return (
    <CardStyled {...{ clickedFeature, panelIsVisible, color }}>
      <CardContainerStyled>
        <TextRight>
          <CrossIcon onClick={onCloseCard} />
        </TextRight>
        <CardHeaderStyled>
          <Avatar {...{ collection, category }} />
          <div>
            <CardTextH4>{name.toLowerCase()}</CardTextH4>
            <div className="header__names-wrap">
              <TextSubtitle>
                {`${collection}: `}
                <span className="text-subtitle__content">{category}</span>
              </TextSubtitle>
            </div>
          </div>
        </CardHeaderStyled>
        <LineStyled />
        <CardBoxedContentStyled>
          {activity && <Paragraph>{activity}</Paragraph>}
          {description && <Paragraph>{description}</Paragraph>}
        </CardBoxedContentStyled>
        <LineStyled />
        <CardBoxedContentStyled>
          <AdressStyled>
            <LocatorIcon />
            <Paragraph>
              {address}
              {neighborhood && <span>, {neighborhood}</span>}
            </Paragraph>
          </AdressStyled>
        </CardBoxedContentStyled>
      </CardContainerStyled>
    </CardStyled>
  )
}

Card.propTypes = {
  setView: PropTypes.func.isRequired,
  clickedFeature: PropTypes.instanceOf(Object).isRequired,
}

export default Card
