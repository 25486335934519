export const DEFAULT_POSITION = [40.378045, -3.745535]
export const DEFAULT_POSITION_INV = [...DEFAULT_POSITION].reverse()

export const ZOOM_IN = 17
export const ZOOM_IN_OFFSET = 25
export const ZOOM_OUT = 14

export const MAP_DOM_TIMEOUT = 300

export const CATEGORIES = {
  Todos: {},
  comercial: {
    smallShops: 'Pequeño comercio',
    gambling: 'Juegos de azar y apuestas',
  },
  gente: {
    classic: 'Tradicional',
    allied: 'Aliado',
    orange: 'Orange',
  },
  lugares: {
    proposal: 'Propuesta',
    memory: 'Memoria',
    potential: 'Lugar Potencial',
    industrial: 'Patrimonio Industrial',
  },
}

export const COLLECTION_NAMES_LIST = ['Todos', 'comercial', 'lugares', 'gente']
