import styled from 'styled-components'
import Markdown from 'markdown-to-jsx'

import { getRem } from 'styles/utils'
import { CrossIcon, LogoOrange } from 'assets/icons'

export const CardStyled = styled.article`
  overflow: hidden;
  height: calc(100vh - 84px);
  width: 46vw;
  min-width: ${getRem(400)};
  position: absolute;
  top: ${({ theme }) => theme.components.CardYPos};
  left: ${({ theme }) => `calc(${theme.components.panelWidth} + 8px)`};
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.darkBlue} 0%,
    #ffffff 144px
  );
  padding: ${getRem(48)} ${getRem(32)};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.components.panelShadow};

  > div p {
    padding-right: ${getRem(32)};
  }

  img {
    max-width: 100%;
  }

  h1 {
    position: relative;
    margin-bottom: ${getRem(60)};
    font-size: ${getRem(20)};
    font-weight: 500;
    color: ${({ theme }) => theme.colors.white};

    &:after {
      content: '';
      position: absolute;
      bottom: -34px;
      left: -10%;
      width: 110%;
      height: 0.5px;
      z-index: 2;
      background-color: ${({ theme }) => theme.colors.white};
    }
  }

  p {
    margin-bottom: ${getRem(32)};
    font-size: ${getRem(13)};
    line-height: 1.6;
  }
`

export const CrossIconStyled = styled(CrossIcon)`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 32px;
`

export const MarkdownStyled = styled(Markdown)`
  height: 100%;
  position: relative;
  overflow-y: scroll;
  z-index: 1;
  padding-left: ${getRem(74)};

  ul {
    padding: 0 ${getRem(12)} 0 ${getRem(40)};
    margin-bottom: ${getRem(32)};
    list-style: circle;

    li {
      font-size: ${getRem(13)};
      line-height: 1.6;
      margin-bottom: ${getRem(4)};
    }
  }
`

export const LogoOrangeStyled = styled(LogoOrange)`
  position: absolute;
  z-index: 3;
  top: 32px;
`
