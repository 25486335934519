import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectCurrentCollection,
  selectCurrentCategory,
  selectCategoriesConfig,
} from 'state/Collections/selectors'
import { setCurrentCollectionAndCategory } from 'state/Collections/actions'

import { getCategoryAccentColor } from 'utils'
import { BreadCrumbStyled, CategoryStyled } from './BreadCrumb.styled'

const BreadCrumb = ({ length }) => {
  const dispatch = useDispatch()
  const collection = useSelector(selectCurrentCollection)
  const category = useSelector(selectCurrentCategory)
  const categoriesConfig = useSelector(selectCategoriesConfig)

  const accentColor = getCategoryAccentColor(category, categoriesConfig)

  const onCollectionClick = () => dispatch(setCurrentCollectionAndCategory({ collection, category: null }))
  const onHomeClick = () => dispatch(setCurrentCollectionAndCategory({ collection: 'Todos', category: null }))

  const _home = () => (
    <span onClick={onHomeClick} aria-hidden="true">
      {'Inicio /  '}
    </span>
  )

  const _curCollection = () => (
    <span onClick={onCollectionClick} aria-hidden="true">
      {collection}
    </span>
  )

  const _currCategory = () => (
    <>
      <span>{' / '}</span>
      <CategoryStyled color={accentColor}>{`${category} (${length})`}</CategoryStyled>
    </>
  )

  const _currLength = () => (
    <CategoryStyled color={accentColor}>{`(${length})`}</CategoryStyled>
  )

  return (
    <BreadCrumbStyled>
      {_home()}
      {_curCollection()}
      {category ? _currCategory() : _currLength()}
    </BreadCrumbStyled>
  )
}

BreadCrumb.propTypes = {
  length: PropTypes.number.isRequired,
}

export default BreadCrumb
