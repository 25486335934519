import React from 'react'

import { setCardContent } from 'state/Info/actions'
import { useDispatch, useSelector } from 'react-redux'

import { selectLegalInfo } from 'state/Info/selectors'
import { setClickedFeature } from 'state/Collections/actions'

import { Youtube, Instagram } from 'assets/icons'
import { FooterStyled } from './Footer.styled'

const Footer = () => {
  const dispatch = useDispatch()
  const legalInfo = useSelector(selectLegalInfo)

  const handleClick = (item) => {
    dispatch(setCardContent(legalInfo[item]))
    dispatch(setClickedFeature(null))
  }

  return (
    <FooterStyled>
      <div>
        <p className="footer__copy">
          <small>© IED Madrid {new Date().getFullYear()}</small>
        </p>
        <nav className="footer__nav">
          <ul>
            <li>
              <span
                tabIndex="0"
                role="button"
                onKeyDown={() => handleClick('condiciones')}
                onClick={() => handleClick('condiciones')}
              >
                Condiciones
              </span>
            </li>
            <li>
              <span
                tabIndex="0"
                role="button"
                onKeyDown={() => handleClick('privacidad')}
                onClick={() => handleClick('privacidad')}
              >
                Privacidad
              </span>
            </li>
            <li>
              <span
                tabIndex="0"
                role="button"
                onKeyDown={() => handleClick('cookies')}
                onClick={() => handleClick('cookies')}
              >
                Política de cookies
              </span>
            </li>
          </ul>
        </nav>
      </div>
      <div className="footer__social">
        <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
          <Youtube />
        </a>
        <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
          <Instagram />
        </a>
      </div>
    </FooterStyled>
  )
}

export default Footer
