import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getProjectInfo } from 'state/Info/actions'
import { selectProjectInfo, selectCardContent } from 'state/Info/selectors'

import { ProjectInfoStyled } from './ProjectInfo.styled'
import { Intro, Navigation, Card, Footer } from './components'

const ProjectInfo = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getProjectInfo())
  }, [])

  const info = useSelector(selectProjectInfo)
  const cardContent = useSelector(selectCardContent)

  if (!info) return null

  const { intro, nav } = info

  return (
    <ProjectInfoStyled>
      <Intro content={intro} />
      <Navigation nav={nav?.links} />
      {cardContent && <Card cardContent={cardContent} />}
      <Footer />
    </ProjectInfoStyled>
  )
}

export default ProjectInfo
