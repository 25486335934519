import styled from 'styled-components'
import { getRem } from 'styles/utils'

export const BreadCrumbStyled = styled.div`
  margin-bottom: ${getRem(20)};
  span {
    font-size: ${getRem(12)};
    color: ${({ theme }) => theme.colors.grays30};
    cursor: pointer;
  }
`
export const CategoryStyled = styled.span`
  color: ${({ color, theme }) => theme.colors[color]};
`
