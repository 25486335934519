import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getKey } from 'utils/key-generator'
import { ZOOM_OUT } from 'constants/index'
import { getCategoryComponent, getCollectionIcon } from 'utils'

import {
  selectCurrentCollection,
  selectCurrentCategory,
  selectCategories,
  selectCategoriesConfig,
} from 'state/Collections/selectors'
import {
  setClickedFeature,
  setCollectionsSearchTerm,
  setCurrentCollectionAndCategory,
} from 'state/Collections/actions'
import { setListVisibility } from 'state/Ui/actions'

import {
  CollectionSelectorStyled,
  CollectionMenuButton,
  CategoryMenuButton,
} from './CollectionSelector.styled'

const CollectionSelector = ({ setView }) => {
  const dispatch = useDispatch()
  const currentCategory = useSelector(selectCurrentCategory)
  const currentCollection = useSelector(selectCurrentCollection)
  const categories = useSelector(selectCategories)
  const categoriesConfig = useSelector(selectCategoriesConfig)

  const isCategorySelected = (category) => currentCategory === category

  const isCollectionsSelected = (collection) => {
    return !currentCategory && collection === currentCollection
  }

  const onSelectMenuItems = (collection, category = null) => {
    dispatch(setClickedFeature(null))
    dispatch(setListVisibility(true))
    dispatch(setCollectionsSearchTerm(''))
    setView(false, ZOOM_OUT)
    dispatch(setCurrentCollectionAndCategory({ collection, category }))
  }

  // eslint-disable-next-line react/prop-types
  const CollectionBlock = ({ children }) => (
    <CollectionSelectorStyled>
      {Object.keys(categories)?.map((collection) => (
        <Fragment key={getKey()}>
          <CollectionMenuButton
            selected={isCollectionsSelected(collection)}
            onClick={() => onSelectMenuItems(collection)}
            key={getKey()}
          >
            {getCollectionIcon(collection)}
            {collection}
          </CollectionMenuButton>
          {React.cloneElement(children, { collection })}
        </Fragment>
      ))}
    </CollectionSelectorStyled>
  )

  const CategoryBlock = ({ collection }) =>
    Object.keys(categories[collection])?.map((key, idx) => {
      const category = categories[collection][key]
      const isLastChild = Object.keys(categories[collection]).length === idx + 1
      return (
        <CategoryMenuButton
          key={getKey()}
          onClick={() => onSelectMenuItems(collection, category)}
          selected={isCategorySelected(category)}
          isLastChild={isLastChild}
        >
          {getCategoryComponent(category, categoriesConfig)}
          {category}
        </CategoryMenuButton>
      )
    })

  return (
    <CollectionBlock>
      <CategoryBlock />
    </CollectionBlock>
  )
}

CollectionSelector.propTypes = {
  setView: PropTypes.func.isRequired,
}

export default CollectionSelector
